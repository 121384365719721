import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox from "../components/TitleBox.jsx";
import {
  getParagraphItem,
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import ItemCard, {
  ResponsiveTitleContentCard,
} from "../components/ResponsiveItemCard.jsx";
import Grid from "@material-ui/core/Grid";
import { parseWithFallback } from "../components/util/Functions";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($topicID: ID!) {
    keystone {
      Topic(where: { id: $topicID }) {
        title
        metaDesc
        bigPageImage {
          publicUrl
        }
        coverImage {
          publicUrl
        }
        paragraphs(sortBy: order_ASC) {
          content
          order
          title
          image {
            publicUrl
          }
        }
        services(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        treatments(where: { draft: false }) {
          title
          slug
          coverImage {
            publicUrl
          }
        }
        doctors(where: { draft: false }) {
          name
          specialization
          slug
          coverImage {
            publicUrl
          }
        }
      }
    }
  }
`;

const TopicTemplate = ({ data }) => {
  const topic = data.keystone.Topic;
  const paragraphs = data.keystone.Topic.paragraphs;
  const services = data.keystone.Topic.services;
  const treatments = data.keystone.Topic.treatments;
  const doctors = data.keystone.Topic.doctors;
  var itemsNumber = paragraphs.length;
  return (
    <Fragment>
      <Helmet>
        <title>{topic.title} - Ambito Clinico PG</title>
        <meta
          name="description"
          content={"Poliambulatorio Giano - Ambito Clinico - " + topic.metaDesc}
        />
      </Helmet>
      <NavBar />
      <TitleBox
        image={topic.bigPageImage ? topic.bigPageImage.publicUrl : undefined}
      >
        <Text variant="homeTitle">{topic.title}</Text>
      </TitleBox>
      <Grid container>
        {paragraphs.map((p, i) =>
          getParagraphItem(
            p.title,
            parseWithFallback(p.content),
            i % 2,
            i % 2 == 0,
            p.image,
            i
          )
        )}
        {services.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Servizi Correlati"
            collapsable={true}
          >
            {services.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {doctors.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Dottori Correlati"
            collapsable={true}
          >
            {doctors.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.name}>
                <ResponsiveTitleContentCard
                  title={x.name}
                  content={x.specialization}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
        {treatments.length > 0 && (
          <GridItemCardList
            colorNumber={itemsNumber++}
            title="Prestazioni Correlate"
            collapsable={true}
          >
            {treatments.map((x, i) => (
              <CardListContainer key={i} navigate={x.slug} queryText={x.title}>
                <ItemCard
                  title={x.title}
                  image={x.coverImage ? x.coverImage.publicUrl : undefined}
                />
              </CardListContainer>
            ))}
          </GridItemCardList>
        )}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default TopicTemplate;
